<template>
  <div class="row flex-center min-vh-100 py-6 text-center">
    <div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
      <div class="card">
        <div class="card-body p-4 p-sm-5">
          <h1 class="h1">404</h1>
          <h2 class="h2">
            The page you're looking for is not found.
          </h2>
          <hr />
          <h2 class="h2">
            Make sure the address is correct and that the page hasn't moved.
            If you think this is a mistake, contact our administrator.
          </h2>
          <h2 class="h2">
            <a class="btn btn-primary btn-sm mt-3" href="/">
              <span class="fas fa-home mr-2"></span>Take me home
            </a>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>

</style>
